import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { mobileRoutes, desktopRoutes, commonRoutes } from "./routes.data";
import { useUser } from "../contexts/AuthContext";

// Define props interface for AppRoutes
interface AppRoutesProps {
	desktop?: boolean;
	eventDetails: any;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ desktop = false, eventDetails }) => {
	const { user } = useUser()
	return (
		<Routes>
			{desktop &&
				desktopRoutes.map((route: any) => {
					if (route.doNotShowIfLoggedIn && user) {

						return
					} else {
						return <Route
							key={route.key}
							path={route.path}
							element={
								route.protected ? (
									<ProtectedRoute>
										<route.element />
									</ProtectedRoute>
								) : (
									<route.element />
								)
							}
						/>
					}

				}

				)}
			{!desktop &&
				mobileRoutes.map((route: any) => {
					if (route.doNotShowIfLoggedIn && user) {
						return
					} else {
						return <Route
							key={route.key}
							path={route.path}
							element={
								route.protected ? (
									<ProtectedRoute>
										<route.element />
									</ProtectedRoute>
								) : (
									<route.element />
								)
							}
						/>
					}
				}



				)}
			{commonRoutes?.map((route: any) => {
				if (route.path === '/address-page' && !eventDetails?.venue) {
					return
				}
				if (route.doNotShowIfLoggedIn && user) {
					return
				} else {
					return <Route
						key={route.key}
						path={route.path}
						element={
							route.protected ? (
								<ProtectedRoute>
									<route.element />
								</ProtectedRoute>
							) : (
								<route.element />
							)
						}
					/>
				}


			}
			)}

			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

export default AppRoutes;
