import React, { useState } from "react";
import { Avatar, Box, Drawer, Typography, IconButton, Button, SwipeableDrawer } from "@mui/material";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { actionButtons, CONNECTION_STATUS, networkEntity } from "../../../config/app";
import { useClipboard } from "../../../reusable/common";
import BusinessCard from "../../../CommomComponents/BusinessCard/BussinessCard";

interface ProfileDrawerProps {
	drawerOpen: boolean;
	setDrawerOpen: (open: boolean) => void;
	selectedConnection: boolean;
	setNotificationPopup: boolean;
	connectionButton: string;
	actionUpdate: () => void;
	setConnectionStatus: string;
	setSelectedConnection: string;
	createUserConnection: () => void;
	removeConnectionId: boolean;
	setRemoveConnectionId: boolean;
	goToChatRoom: () => {}
}

const ProfileDrawer: React.FC<ProfileDrawerProps> = ({
	drawerOpen,
	setDrawerOpen,
	selectedConnection,
	setNotificationPopup,
	connectionButton,
	actionUpdate,
	setConnectionStatus,
	setSelectedConnection,
	createUserConnection,
	removeConnectionId,
	setRemoveConnectionId,
	goToChatRoom,
}) => {


	return (
		<SwipeableDrawer
			onOpen={() => {
				setDrawerOpen(true);
			}}
			className="drawer_network"
			anchor="bottom"
			open={drawerOpen}
			onClose={() => setDrawerOpen(false)}
			style={{ padding: "10px" }}
		>
			<div className="drawer-top-line" aria-hidden="true" onClick={() => { setDrawerOpen(false) }}></div>
			<div className="d-flex justify-content-end mb-1 me-4">
				<Icon
					role="button"
					icon={`material-symbols-light:close`}
					width={"24px"}
					height={"24px"}
					onClick={() => {
						setDrawerOpen(false);
					}}
				/>
			</div>
			{selectedConnection && !removeConnectionId ? (
				<>

					<div style={{ padding: "10px" }}>
						<div className="" style={{ width: "auto" }}>
							<BusinessCard
								width={"auto"}
								connection={selectedConnection}
								goToChatRoom={selectedConnection?.connection_status === CONNECTION_STATUS.CONNECTED ? goToChatRoom : null}
							/>
						</div>

						<div>
							{connectionButton &&
								connectionButton !== actionButtons.ACCEPT && (
									<button
										className="accept_network-desktop modal-screen"
										onClick={() => {
											if (
												connectionButton !==
												actionButtons.CONNECT
											) {
												actionUpdate(
													selectedConnection
												);
											} else {
												createUserConnection(
													selectedConnection?.connection_id
												);
											}
											setDrawerOpen(false);
										}}
									>
										{connectionButton}
									</button>
								)}
						</div>
					</div>
				</>) : (
				<div>
					<div className="confirm-remove-section" style={{ padding: "15px" }}>
						<div className="remove-connection">
							<p>Remove Connection</p>
						</div>
						<p className="deleted-line">
							Are you sure that you want to remove this connection?
						</p>
						<div className="confirm-remove_container d-flex justify-content-center">
							<button
								className="cancel-button"
								onClick={() => {
									setDrawerOpen(false);
									setRemoveConnectionId(false);
									setSelectedConnection("")

								}}
								style={{ padding: "10px 60px" }}
							>
								Cancel
							</button>
							<button
								className="remove-button-new "
								onClick={() => {
									actionUpdate(selectedConnection, networkEntity.REMOVE);
									setDrawerOpen(false);
									setRemoveConnectionId(false);
									setSelectedConnection("")
								}}
							>
								Yes
							</button>
						</div>
					</div>
				</div>
			)}


		</SwipeableDrawer>
	);
};

export default ProfileDrawer;
