import { Badge } from "lucide-react";

import CreateAccount from "./../components/CreateAccount/CreateAccount";
import WelcomePage from "./../components/WelcomePage/WelcomePage";
import VerifyOtpScreen from "./../components/VerifyOtp/VerifyOtp";
import Notification from "./../components/Notification/Notification";
import InterestsPage from "./../components/InterestsPage/InterestsPage";
import SignupPage from "../components/SingupPage/SignupPage";
import VerifySignupOtp from "./../components/VerifySignupOtp/VerifySignupOtp";
import TermsAndConditions from "./../components/TermsAndConditions/TermsAndConditions";
import PageNotFound from "./../components/ErrorsComponents/PageNotFound/PageNotFound";
import BadRequest from "./../components/ErrorsComponents/BadRequest/BadRequest";
import InternalError from "./../components/ErrorsComponents/InternalError/InternalError";
import UnderMaintenance from "./../components/ErrorsComponents/UnderMaintenance/UnderMaintenance";
import UnregisteredEmail from "./../components/ErrorsComponents/UnregisteredEmail/UnregisteredEmail";
import HomeScreen from "./../components/HomeScreen/HomeScreen";
import EventApp from "./../components/EventApp/EventApp";
import LoginAccEmail from "./../components/LoginAccEmail/LoginAccEmail";
import EventDetails from "./../components/EventDetails/EventDetails";
import SearchPage from "./../components/SearchPage/SearchPage";
import Agenda from "./../components/Agenda/Agenda";
import Vcard from "./../components/ProfileView/Vcard/Vcard";
import MyProfile from "./../components/MyProfile/ProfilePage";
import MyProfileBusinessCard from "./../components/MyProfile/MyProfileBusinessCard/MyProfileBusinessCard";
import ProfileForm from "./../components/MyProfile/ProfileForm/ProfileForm";
import MyTickets from "./../components/MyProfile/MyTickets/MyTickets";
import ChangePassword from "./../components/ChangePassword/ChangePassword";
import Notifications from "./../components/Notification/NotificationPage/NotificationPage";
import LiveEventsFilter from "./../components/LiveEventsFilter/LiveEventsFilter";
import Sponsors from "./../components/Sponsors/Sponsors";
import Exhibitors from "../components/Exhibitors/Exhibitors";
import SpeakerPage from "./../components/SpeakerPage/SpeakerPage";
import SurveyQuestion from "./../components/Survey/Survey";
import Feedback from "./../components/Feedback/Feedback";
import AddressPage from "./../components/Address/Address";
import FloorPlan from "./../components/FloorPlan/FloorPlan";
import Gallery from "./../components/Gallery/Gallery";
import Partners from "./../components/Partners/Partners";
import QuizPage from "./../components/QuizPage/QuizPage";
import Menu from "./../components/Menu/Menu";
import MyEvent from "./../components/MyEvent/MyEvent";
import LoginAccount from "./../components/LoginAccount/LoginAccount";
import SideNavbar from "./../components/SideNavbar/SideNavbar";
import Network from "./../components/Network/Network";
import ProfileView from "./../components/ProfileView/ProfileView";
import VCardPage from "./../components/ProfileView/VCardPage/VCardPage";
import EventDrawerDesktop from "./../components/EventDrawerDesktop/EventDrawerDesktop";
import NotificationModal from "./../components/Notification/NotificationModal/NotificationModal";
import SponsorsModal from "../EntityPages/EntityPage/ItemDetails";
import ItemDetails from "../EntityPages/EntityPage/ItemDetails";
import SigninWithEmail from "./../components/SigninWithEmail/SigninWithEmail";
import ForgotPasswordDesktop from "./../components/ForgotPasswordDesktop/ForgotPasswordDesktop";
import ForgotPasswordDesktopOtp from "./../components/ForgotPasswordDesktop/ForgotPasswordDesktopOtp/ForgotPasswordDesktopOtp";
import Logout from "./../components/Logout/Logout";
import CustomModule from "./../components/CustomModule/CustomModule";
import ModuleItem from "./../components/CustomModule/ModuleItem";
import Chats from "../components/Chats/Chats";
import PhotoBooth from "./../components/PhotoBooth/PhotoBooth";
import Meetings from "../components/Meetings/Meetings";
import ScanBadge from "../components/Scanner/ScanBadge";
import ChatRoom from "../components/MobileChatInterface/MobileChatInterface";

// TODO: This POC will be removed later
import NotificationPOC from "./../sample/NotificationPOC";

export const commonRoutes: any = [
	// TODO: This POC will be removed later
	{
		key: "Notifications POC",
		path: "/notification-poc",
		element: NotificationPOC,
		protected: false
	},
	{ key: "Sponsors", path: "/sponsors/*", element: Sponsors, protected: false },
	{ key: "Speakers", path: "/speakers", element: SpeakerPage, protected: false },
	{ key: "Speaker Page", path: "/speakers/details", element: SpeakerPage, protected: false },
	{ key: "Exhibitors", path: "/exhibitors/*", element: Exhibitors, protected: false },
	{ key: "Address Page", path: "/address-page", element: AddressPage, protected: false },
	{ key: "Network", path: "/network", element: Network, protected: true },
	{ key: "Partners", path: "/partners/*", element: Partners, protected: false },
	{ key: "Agenda", path: "/agenda/*", element: Agenda, protected: false },
	{ key: "Scanner", path: "/scanner", element: ScanBadge, protected: true },
	{ key: "Home Page", path: "/", element: EventApp, protected: false },
	{ key: "Custom Module", path: "/m/:moduleId", element: CustomModule, protected: false },
	{ key: "Module Item", path: "/m/:moduleId/i/:itemId", element: ModuleItem, protected: false },
	{ key: "Logout", path: "/logout", element: Logout, protected: true },
	{
		key: "Terms And Conditions",
		path: "/terms-condition",
		element: TermsAndConditions,
		protected: false
	},
	{ key: "Page Not Found", path: "/page-notfound", element: PageNotFound, protected: false },
	{ key: "Bad Request", path: "/bad-request", element: BadRequest, protected: false },
	{ key: "Internal Error", path: "/internal-error", element: InternalError, protected: false },
	{
		key: "Under Maintenance",
		path: "/under-maintenance",
		element: UnderMaintenance,
		protected: false
	},
	{
		key: "Unregistered Email",
		path: "/unregistered-email",
		element: UnregisteredEmail,
		protected: false
	},
	{ key: "Meetings", path: "/meetings/*", element: Meetings, protected: true },
	{
		key: "Create Meetings",
		path: "/meetings/create-meeting",
		element: Meetings,
		protected: true
	},
	{ key: "Edit Meetings", path: "/meetings/edit-meeting", element: Meetings, protected: true },
	{
		key: "Signup Page",
		path: "/signup-page",
		element: SignupPage,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{
		key: "Login Account",
		path: "/login-account",
		element: LoginAccount,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{ key: "My Profile", path: "/my-profile", element: MyProfile, protected: true }
];

export const mobileRoutes: any = [
	{
		key: "Create Account",
		path: "/create-account",
		element: CreateAccount,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{
		key: "Verify OTP",
		path: "/verify-otp",
		element: VerifyOtpScreen,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{ key: "Welcome Page", path: "/welcome", element: WelcomePage, protected: false },
	{ key: "Notification", path: "/notification", element: Notification, protected: false },
	{ key: "Interest Page", path: "/interest-page", element: InterestsPage, protected: false },
	{
		key: "Verify Signup OTP",
		path: "/verifysingup-otp",
		element: VerifySignupOtp,
		protected: false
	},

	{ key: "Home Screen", path: "/home-screen", element: HomeScreen, protected: false },
	{ key: "Login AccEmail", path: "/login-accemail", element: LoginAccEmail, protected: false },
	{ key: "Event Details", path: "/event-details", element: EventDetails, protected: false },
	{ key: "Search Page", path: "/search-page", element: SearchPage, protected: false },
	{ key: "Side Navbar", path: "/side-navbar", element: SideNavbar, protected: false },
	{ key: "Menu", path: "/menu", element: Menu, protected: false },
	{ key: "Floor Plan", path: "/floor-plan", element: FloorPlan, protected: false },
	{ key: "Feedback", path: "/feedback", element: Feedback, protected: false },
	{ key: "Quiz Page", path: "/quiz-page", element: QuizPage, protected: false },
	{ key: "Profile View", path: "/profile-view", element: ProfileView, protected: true },
	{ key: "Agenda Protected", path: "/agenda", element: Agenda, protected: false },
	{ key: "Vcard", path: "/vcard", element: Vcard, protected: true },
	{ key: "Badge", path: "/badge", element: Badge, protected: true },
	{
		key: "My Profile BusinessCard",
		path: "/myprofile-businesscard",
		element: MyProfileBusinessCard,
		protected: true
	},
	{
		key: "Forgot Password Desktop",
		path: "/forgot-password",
		element: ForgotPasswordDesktop,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{
		key: "Forgot Password Desktop OTP",
		path: "/forgotpassword-desktop-otp",
		element: ForgotPasswordDesktopOtp,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{ key: "Profile Form", path: "/profile-form", element: ProfileForm, protected: true },
	{ key: "My Tickets", path: "/my-tickets", element: MyTickets, protected: true },
	{ key: "Change Password", path: "/change-password", element: ChangePassword, protected: true },
	{ key: "Notifications", path: "/notifications", element: Notifications, protected: true },
	{ key: "Live Events", path: "/live-events", element: LiveEventsFilter, protected: true },
	{ key: "Sponsors", path: "/sponsors", element: Sponsors, protected: false },
	{ key: "Exhibitors", path: "/exhibitors", element: Exhibitors, protected: false },
	{ key: "Speaker Page", path: "/speakers", element: SpeakerPage, protected: false },
	{ key: "Speaker Page", path: "/speakers/details", element: SpeakerPage, protected: false },
	{ key: "Survey", path: "/survey", element: SurveyQuestion, protected: true },
	{ key: "Feedback", path: "/feedback", element: Feedback, protected: true },
	{ key: "Floor Plan Protected", path: "/floor-plan", element: FloorPlan, protected: false },
	{ key: "Gallery", path: "/gallery", element: Gallery, protected: false },
	{ key: "Quiz Page Protected", path: "/quiz-page", element: QuizPage, protected: true },
	{ key: "Menu Protected", path: "/menu", element: Menu, protected: true },
	{ key: "My Event Protected", path: "/myevent", element: MyEvent, protected: true },
	{ key: "Photo Booth", path: "/photo-booth", element: PhotoBooth, protected: true },
	{ key: "Chats", path: "/chats", element: Chats, protected: true },
	{ key: "ChatRoom", path: "/chats/:roomId", element: ChatRoom, protected: true }
];

export const desktopRoutes: any = [
	{
		key: "Create Account",
		path: "/create-account",
		element: CreateAccount,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{ key: "Verify OTP", path: "/verify-otp", element: VerifyOtpScreen, protected: false },
	{ key: "Welcome Page", path: "/welcome", element: WelcomePage, protected: false },
	{ key: "Notification", path: "/notification", element: Notification, protected: false },
	{ key: "Interest Page", path: "/interest-page", element: InterestsPage, protected: false },
	{
		key: "Verify Signup OTP",
		path: "/verifysingup-otp",
		element: VerifySignupOtp,
		protected: false
	},
	{ key: "Home Screen", path: "/home-screen", element: HomeScreen, protected: false },
	{
		key: "Login Account Email",
		path: "/login-accemail",
		element: LoginAccEmail,
		protected: true
	},
	{ key: "Event Details", path: "/event-details", element: EventDetails, protected: true },
	{ key: "Search Page", path: "/search-page", element: SearchPage, protected: true },
	{ key: "Side Navbar", path: "/side-navbar", element: SideNavbar, protected: false },
	{ key: "Menu", path: "/menu", element: Menu, protected: true },
	{ key: "Floor Plan", path: "/floor-plan", element: FloorPlan, protected: false },
	{ key: "Feedback", path: "/feedback", element: Feedback, protected: true },
	{ key: "Quiz Page", path: "/quiz-page", element: QuizPage, protected: true },
	{ key: "Profile View", path: "/profile-view", element: ProfileView, protected: true },
	{ key: "Vcard", path: "/vcard-page", element: VCardPage, protected: false },
	{ key: "Vcard Protected", path: "/vcard", element: Vcard, protected: false },
	{ key: "Profile Form", path: "/profile-form", element: ProfileForm, protected: true },
	{ key: "My Tickets", path: "/my-tickets", element: MyTickets, protected: true },
	{
		key: "Forgot Password Desktop",
		path: "/forgot-password",
		element: ForgotPasswordDesktop,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{
		key: "Forgot Password Desktop OTP",
		path: "/forgotpassword-desktop-otp",
		element: ForgotPasswordDesktopOtp,
		protected: false,
		doNotShowIfLoggedIn: true
	},
	{ key: "Badge", path: "/badge", element: Badge, protected: true },

	{
		key: "My Profile Business Card",
		path: "/myprofile-businesscard",
		element: MyProfileBusinessCard,
		protected: true
	},
	{ key: "Profile Form Protected", path: "/profile-form", element: ProfileForm, protected: true },
	{ key: "My Tickets", path: "/my-tickets", element: MyTickets, protected: true },
	{ key: "Change Password", path: "/change-password", element: ChangePassword, protected: true },
	{ key: "Notifications", path: "/notifications", element: Notifications, protected: true },
	{ key: "Live Events", path: "/live-events", element: LiveEventsFilter, protected: true },
	{ key: "Sponsors", path: "/sponsors", element: Sponsors, protected: false },
	{ key: "Exhibitors", path: "/exhibitors", element: Exhibitors, protected: false },
	{ key: "Speakers", path: "/speakers", element: SpeakerPage, protected: false },
	{ key: "Speaker Page", path: "/speakers/details", element: SpeakerPage, protected: false },
	{ key: "Survey", path: "/survey", element: SurveyQuestion, protected: true },
	{ key: "Gallery", path: "/gallery", element: Gallery, protected: false },
	{ key: "My Event", path: "/myevent", element: MyEvent, protected: true },
	{
		key: "Notification Modal",
		path: "/notification-modal",
		element: NotificationModal,
		protected: false
	},
	{ key: "Partners Modal", path: "/partners-modal", element: ItemDetails, protected: false },
	{ key: "Sponsors Modal", path: "/sponsors-modal", element: SponsorsModal, protected: false },
	{
		key: "Signin With Email",
		path: "/signin-withemail",
		element: SigninWithEmail,
		protected: false
	},
	{ key: "Photo Booth", path: "/photo-booth", element: PhotoBooth, protected: true },
	{ key: "Chats", path: "/chats", element: Chats, protected: true },
	{ key: "ChatRoom", path: "/chats/:roomId", element: ChatRoom, protected: true }
];
