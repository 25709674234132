// ConnectionListDekstop.tsx
import React from "react";
import { Connection } from "./types";
import ConnectionActionsDesktop from "../ConnectionActions/ConnectionActionsDesktop";
import { profileImage } from "../../../reusable/images";
import InfiniteScroll from "react-infinite-scroller";
import { ClipLoader } from "react-spinners";
import { spinnerStyle } from "../../../config/app";
import { truncateText } from "../../../reusable/common";
import { networkEntity, TABS } from "../../../config/app";

interface ConnectionListDesktopProps {
	activeTab: string;
	connections: any[];
	filterConnections: () => any[];
	attendees: any[];
	handleConnectionClick: (connection: any) => void;
	userId: string;
	setAttendeesAttendeesData: (data: any) => void;
	connectionButton: string;
	setConnectionButton: (value: string) => void;
	setRemoveConnectionId: (id: string | boolean) => void;
	fetchAttendees: () => void;
	searchTerm: string;
	hasMore: boolean;
	loading: boolean;
	setSelectedConnections: "";
	createUserConnection: () => void;
	actionUpdate: () => void;
	fetchAttendeesSearch: () => void;
	filterTags: any;
	currentAttendeesCount: number;
	handleWithdrawClick: () => void;
	disabledButton: any,
	goToChatRoom: () => {}
}

const ConnectionListDesktop: React.FC<ConnectionListDesktopProps> = ({
	activeTab,
	filterConnections,
	attendees,
	handleConnectionClick,
	setAttendeesAttendeesData,
	userId,
	setConnectionButton,
	connectionButton,
	setRemoveConnectionId,
	fetchAttendees,
	searchTerm,
	hasMore,
	loading,
	setSelectedConnections,
	createUserConnection,
	actionUpdate,
	filterTags,
	fetchAttendeesSearch,
	currentAttendeesCount,
	handleWithdrawClick,
	disabledButton,
	goToChatRoom
}) => {
	const renderLoader = () => (
		<>
			{loading && (
				<div className="d-flex justify-content-center align-items-center flex-column add-people-loader">
					<ClipLoader
						color="var(--primary-color)"
						loading={true}
						size={50}
						aria-label="Loading Spinner"
					/>
				</div>
			)}
		</>
	);


	return (
		<>
			{activeTab === "Business Card" ? (
				<div className="business-card-container scrollable-div-other">
					<InfiniteScroll
						loadMore={() => {
							if (loading) return;
							fetchAttendees(currentAttendeesCount);
						}}
						hasMore={hasMore}
						loader={renderLoader()}
						useWindow={false}
					>
						{attendees?.length > 0 ? (
							attendees?.map(connection => (
								<BusinessCard key={connection?.id} connection={connection} goToChatRoom={goToChatRoom} />
							))
						) : (
							<p>No Business Card connections found.</p>
						)}
					</InfiniteScroll>
				</div>
			) : (
				<div className="scrollable-div-network" style={{ height: "100%" }}>
					<InfiniteScroll
						loadMore={() => {
							if (loading) return;
							fetchAttendees(currentAttendeesCount);
						}}
						hasMore={hasMore}
						loader={renderLoader()}
						useWindow={false}
					>
						{attendees?.length > 0 ? (
							attendees?.map(connection => (
								<div className="profile-card" key={connection?.id}>
									<div
										className="details-section"
										onClick={() => {
											handleConnectionClick(connection);
											setAttendeesAttendeesData(connection);
											setConnectionButton("");
										}}
									>
										<div className="profile-image-wrapper">
											{profileImage(
												connection?.image_url,
												connection?.att_name,
												40,
												{
													width: "40px",
													height: "40px"
												}
											)}
										</div>
										<div className="profile-info">
											<h3>{truncateText(connection?.att_name)}</h3>
											<p>{truncateText(connection?.designation)}</p>
											<p>{truncateText(connection?.organisation)}</p>
										</div>
									</div>

									<ConnectionActionsDesktop
										activeTab={activeTab}
										connection={connection}
										userId={userId}
										handleConnectionClick={handleConnectionClick}
										removeDrawer={true}
										setConnectionButton={setConnectionButton}
										connectionButton={connectionButton}
										setRemoveConnectionId={setRemoveConnectionId}
										setSelectedConnections={setSelectedConnections}
										createUserConnection={createUserConnection}
										actionUpdate={actionUpdate}
										handleWithdrawClick={handleWithdrawClick}
										disabledButton={disabledButton}
										goToChatRoom={goToChatRoom}
									/>
								</div>
							))
						) : (
							<p style={{ textAlign: "center", display: loading ? "none" : "block" }}>
								No connections found for this category.
							</p>
						)}
					</InfiniteScroll>
				</div>
			)}
		</>
	);
};

export default ConnectionListDesktop;
