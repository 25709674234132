// ConnectionActionsDesktop.tsx
import React, { useState, useRef, useEffect } from "react";
import { Connection } from "./types";
import { actionButtons, networkEntity } from "../../../config/app";
import { Icon } from '@iconify/react/dist/iconify.js';

interface ConnectionActionsDesktopProps {
    connection: Connection;
    activeTab: string;
    userId: string;
    handleWithdrawClick: (id: string) => void;
    setConnectionButton: string,
    setRemoveConnectionId: boolean,
    setActionFetch: boolean,
    createUserConnection: () => void
    actionUpdate: () => void
    disabledButton: any
    goToChatRoom: () => {}
}

const ConnectionActionsDesktop: React.FC<ConnectionActionsDesktopProps> = ({
    connection,
    activeTab,
    userId,
    handleWithdrawClick,
    setConnectionButton,
    setRemoveConnectionId,
    createUserConnection,
    actionUpdate,
    disabledButton,
    goToChatRoom
}) => {

    const [showRemoveConnection, setShowRemoveConnection] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggleRemoveConnection = () => {
        setShowRemoveConnection((prev) => !prev);
    };


    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowRemoveConnection(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div>
            {/* "Sent" Tab */}
            {activeTab === "Sent" && connection?.connection_status === networkEntity.SENT && connection?.created_by === userId && (
                <button className="connect-button" onClick={() => {
                    handleWithdrawClick(connection),
                        setConnectionButton(actionButtons.WITHDRAW)
                }}>
                    {actionButtons.WITHDRAW}
                </button>
            )}

            {/* "All" Tab */}
            {activeTab === "All" && (
                <>
                    {connection?.connection_status === networkEntity.ACCEPT && (
                        <>
                            <button className='options-button'>
                                <Icon
                                    icon="material-symbols-light:chat-outline-rounded"
                                    height={24}
                                    width={24}
                                    onClick={() => goToChatRoom(connection?.attendee_id)}
                                />
                            </button>
                            <button className="options-button" onClick={() => handleToggleRemoveConnection()}
                            >
                                ⋮
                            </button></>)}
                    {connection?.created_by === userId && connection?.connection_status === networkEntity.SENT && (
                        <button className="connect-button" onClick={() => {
                            handleWithdrawClick(connection),
                                setConnectionButton(actionButtons.WITHDRAW),
                                setRemoveConnectionId(false)

                        }}>
                            {actionButtons.WITHDRAW}
                        </button>
                    )}
                    {connection?.connection_status === networkEntity.SENT && connection?.created_by !== userId && (
                        <div className="action-buttonss">
                            <button className="connect-button-decline" onClick={() => {
                                actionUpdate(connection, networkEntity.REJECT)
                                setConnectionButton(actionButtons.REJECT),
                                    setRemoveConnectionId(false)

                            }}
                            >{actionButtons.DECLINE}</button>
                            <button className="connect-button-accept" onClick={() => {
                                actionUpdate(connection, networkEntity.ACCEPT)
                                setConnectionButton(actionButtons.ACCEPT),
                                    setRemoveConnectionId(false)
                                // setActionFetch(true)

                            }} >{actionButtons.ACCEPT}</button>
                        </div>
                    )}
                    {connection?.connection_status === null && (
                        <button disabled={disabledButton === connection.attendee_id} className="connect-button" onClick={() => {
                            createUserConnection(connection),
                                setConnectionButton(actionButtons.CONNECT),
                                setRemoveConnectionId(false)
                        }}
                        >{actionButtons.CONNECT}</button>
                    )}
                </>
            )}

            {/* "My Network" Tab */}
            {activeTab === "My Network" && connection?.connection_status === networkEntity.ACCEPT && (
                <>
                    <button className='options-button'>
                        <Icon
                            icon="material-symbols-light:chat-outline-rounded"
                            height={24}
                            width={24}
                            onClick={() => goToChatRoom(connection?.attendee_id)}
                        />
                    </button>
                    <button className="options-button" onClick={handleToggleRemoveConnection}>
                        ⋮
                    </button>
                </>)}

            {/* "Received" Tab */}
            {activeTab === "Received" && connection?.connection_status === networkEntity.SENT && connection?.created_by !== userId && (
                <div className="action-buttonss">
                    <button className="connect-button-decline" onClick={() => {
                        actionUpdate(connection, networkEntity.REJECT)
                        setConnectionButton(actionButtons.REJECT),
                            setRemoveConnectionId(false)
                        // setActionFetch(true)

                    }}
                    >{actionButtons.DECLINE}</button>
                    <button className="connect-button-accept" onClick={() => {
                        // handleWithdrawClick(connection),
                        actionUpdate(connection, networkEntity.ACCEPT)
                        setConnectionButton(actionButtons.ACCEPT),
                            setRemoveConnectionId(false)
                        // setActionFetch(true)

                    }} >{actionButtons.ACCEPT}</button>
                </div>
            )}

            {showRemoveConnection && (
                <div
                    role="button"
                    className="remove-conncetion-contaner"
                    aria-hidden="true"
                    ref={dropdownRef}
                    onClick={() => {
                        handleWithdrawClick(connection),
                            handleToggleRemoveConnection(),
                            setRemoveConnectionId(true),
                            setConnectionButton("")

                    }}
                >
                    <div className="remove-conncetion-sec">
                        <span>Remove Connection</span>
                    </div>
                </div>
            )}
        </div>


    );
};

export default ConnectionActionsDesktop;
