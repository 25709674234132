import React from "react";
import "./BusinessCard.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useClipboard, truncateText } from "../../reusable/common";
import { profileImage } from "../../reusable/images";

interface BusinessCardProps {
	connection: {
		id: number;
		att_name: string;
		designation: string;
		email: string;
		phone: string;
		avatar: string;
		socialLinks: {
			linkedin?: string;
			twitter?: string;
			website?: string;
			whatsapp?: string;
		};
	};
	width: string;
	goToChatRoom?: () => {};
}

const BusinessCard: React.FC<BusinessCardProps> = ({ connection, width, goToChatRoom }) => {
	const emailClipboard = useClipboard();
	const phoneClipboard = useClipboard();
	return (
		<div
			className={`business-card ${width ? "business_card-width" : ""}`}
		// style={{ width: width ? width : "400px" }}
		>
			{" "}
			<div className="card-content">
				<div className="user-info">
					<div className=" ">
						<div className="profile-container" style={{ textAlign: "left" }}>
							<div
								className="d-flex  align-items-center"
								style={{ justifyContent: "space-between" }}
							>
								<h2>{truncateText(connection?.att_name)}</h2>

								<div className="contact-info">
									<div className="profile-picture">
										{profileImage(
											connection?.image_url,
											connection?.att_name,
											40,
											{
												width: "40px",
												height: "40px"
											}
										)}
									</div>
								</div>
							</div>
							<div>
								{connection?.designation && (
									<p className="title">{truncateText(connection?.designation)}</p>
								)}
							</div>
							<div>
								{connection?.organisation && (
									<p className="company-name">
										{truncateText(connection?.organisation)}
									</p>
								)}
							</div>
						</div>
					</div>

					<div>
						{connection?.email_id && (
							<h3 className="show">
								<Icon
									icon="mdi-light:email"
									width="20px"
									height="20px"
									className="copy-icon"
									style={{ marginRight: "8px", cursor: "pointer" }}
									onClick={() =>
										emailClipboard.copyToClipboard(connection.email_id)
									}
								/>

								{connection.email_id}
								<Icon
									icon={emailClipboard.copied ? "ic:round-check" : "stash:copy"}
									color="--comp-primary-color"
									width="20px"
									height="20px"
									className="copy-icon"
									style={{ marginLeft: "8px", cursor: "pointer" }}
									onClick={() =>
										emailClipboard.copyToClipboard(connection.email_id)
									}
								/>
								{/* {emailClipboard.copied && <span> Copied!</span>} */}
							</h3>
						)}
						{connection?.phone_number && (
							<div>
								<h3 className="show">
									<Icon
										icon="ph:phone-thin"
										width="20px"
										height="20px"
										className="copy-icon"
										style={{ marginRight: "8px", cursor: "pointer" }}
										onClick={() =>
											phoneClipboard.copyToClipboard(connection.phone_number)
										}
									/>
									{connection.dial_code && (
										<span>
											{connection.dial_code.startsWith("+")
												? connection.dial_code
												: `+${connection.dial_code}`}
										</span>
									)}
									{connection.phone_number}
									<Icon
										icon={
											phoneClipboard.copied ? "ic:round-check" : "stash:copy"
										}
										color="--comp-primary-color"
										width="20px"
										height="20px"
										className="copy-icon"
										style={{ marginLeft: "8px", cursor: "pointer" }}
										onClick={() =>
											phoneClipboard.copyToClipboard(connection.phone_number)
										}
									/>
									{/* {phoneClipboard.copied && <span> Copied!</span>} */}
								</h3>
							</div>
						)}
					</div>
					<div className="business-card-icons-wrapper">
						<div className="social-icons">
							{connection?.linkedin_url && (
								<div>
									<a
										href={connection?.linkedin_url}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Icon
											icon="cib:linkedin-in"
											width="20px"
											height="20px"
											className="primary-color-icons"
										/>
									</a>
								</div>
							)}
							{connection?.twitter_url && (
								<div>
									<a
										href={connection.twitter_url}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Icon
											icon="fa6-brands:square-x-twitter"
											width="20px"
											height="20px"
											className="primary-color-icons"
										/>
									</a>
								</div>
							)}

							{connection?.website && (
								<div>
									<a
										href={connection.website}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Icon
											icon="hugeicons:internet"
											width="20px"
											height="20px"
											className="primary-color-icons"
										/>
									</a>
								</div>
							)}
						</div>
						{goToChatRoom && <button className='go-to-chat-button'>
							<Icon
								icon="material-symbols-light:chat-outline-rounded"
								height={24}
								width={24}
								onClick={() => goToChatRoom(connection?.attendee_id)}
							/>
						</button>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BusinessCard;
