import React from 'react';
import './VenueCard.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from '@mui/material';

interface VenueCardProps {
  name: string;
  address: string;
  rating: number;
  maxRating: number;
  imageUrl: string;
  longitude: string;
  latitude: string
}

const VenueCard: React.FC<VenueCardProps> = ({ name, address, rating, maxRating, imageUrl, latitude, longitude }) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= maxRating; i++) {
      stars.push(
        <span key={i} className={`star ${i <= rating ? 'filled' : ''}`}>
          ★
        </span>
      );
    }
    return stars;
  };




  return (
    <>
      <div className='phone-view'>
        <div className="venue-card">
          <div className="venue-info">

            <div className='venue-link'>
              <h3 className="venue-name">{name}</h3>

            </div>
            <div className='d-flex align-items-center mt-2'>
              <Icon
                icon={`fluent:location-16-regular`}
                width={"20px"}
                height={"24px"}
                weight={"24px"}
                className="primary-color-iconss flex-shrink-0 "
              />

              <Link
                className="min-link-text"
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="venue-address">{address}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='desktop-view'>
        <div className="venue-card">
          {/* <img src={imageUrl} alt={name} className="venue-image" /> Render the image */}
          <div className="venue-info">

            <div className='venue-link'>
              <h3 className="venue-name">{name}</h3>


            </div>
            <div className='d-flex align-items-center mt-2'>
              <Icon
                icon={`fluent:location-16-regular`}
                width={"20px"}
                height={"20px"}
                weight={20}
                className="primary-color-iconss flex-shrink-0 "
              />
              <Link
                className=""
                href={`https://www.google.com/maps/search/?api=1&query=${address?.replace(/ /g, "+")}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="venue-address">{address}</p>
              </Link>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default VenueCard;
