import React, { useState, useEffect, useRef } from 'react';
import { actionButtons, networkEntity } from "../../../config/app";
import { Icon } from '@iconify/react/dist/iconify.js';

interface Connection {
    connection_status: number | null;
    created_by: string;
}

interface ConnectionActionsProps {
    activeTab: string;
    connection: Connection;
    userId: string;
    handleConnectionClick: () => void,
    setConnectionButton: string,
    setRemoveConnectionId: boolean,
    setSelectedConnections: "",
    createUserConnection: () => void
    actionUpdate: () => void
    disabledButton: any
    goToChatRoom: () => {};
}

const ConnectionActions: React.FC<ConnectionActionsProps> = ({
    activeTab,
    connection,
    userId,
    handleConnectionClick,
    setConnectionButton,
    setRemoveConnectionId,
    setSelectedConnections,
    createUserConnection,
    actionUpdate,
    disabledButton,
    goToChatRoom
}) => {
    const [showRemoveConnection, setShowRemoveConnection] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleToggleRemoveConnection = () => {
        setShowRemoveConnection((prev) => !prev);
    };


    useEffect(() => {

        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowRemoveConnection(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);





    return (
        <div>
            {activeTab === 'Sent' && connection?.connection_status === networkEntity.SENT && connection?.created_by === userId && (
                <button className="connect-button" onClick={() => {
                    handleConnectionClick(connection),
                        setConnectionButton(actionButtons.WITHDRAW),
                        setRemoveConnectionId(false)
                }}>{actionButtons.WITHDRAW}</button>
            )}

            {/* "All" Tab */}
            {activeTab === 'All' && (
                <>
                    {connection?.connection_status === networkEntity.ACCEPT && (
                        <>
                            <button className='options-button'>
                                <Icon
                                    icon="material-symbols-light:chat-outline-rounded"
                                    height={24}
                                    width={24}
                                    onClick={() => goToChatRoom(connection?.attendee_id)}
                                />
                            </button>
                            <button className="options-button"
                                onClick={() => {
                                    setShowRemoveConnection((prev) => !prev);
                                }}
                            >
                                ⋮
                            </button>
                        </>
                    )}
                    {connection?.connection_status === networkEntity.SENT && connection?.created_by === userId && (
                        <button className="connect-button" onClick={() => {
                            handleConnectionClick(connection),
                                setConnectionButton(actionButtons.WITHDRAW),
                                setRemoveConnectionId(false)
                        }} >{actionButtons.WITHDRAW}</button>
                    )}
                    {connection?.connection_status === networkEntity.SENT && connection?.created_by !== userId && (
                        <div className="action-buttons">
                            <Icon
                                role="button"
                                icon={`material-symbols-light:cancel-outline`}
                                width={"44px"}
                                height={"44px"}
                                className="primary-color-icons"
                                onClick={() => {
                                    actionUpdate(connection, networkEntity.REJECT),
                                        setConnectionButton(actionButtons.REJECT),
                                        setRemoveConnectionId(false)
                                }}
                            />
                            <Icon
                                role="button"
                                icon={`material-symbols-light:check-circle-outline`}
                                width={"44px"}
                                height={"44px"}
                                className="primary-color-icons"
                                onClick={() => {
                                    actionUpdate(connection, networkEntity.ACCEPT),
                                        setConnectionButton(actionButtons.ACCEPT),
                                        setRemoveConnectionId(false)
                                }}
                            />
                        </div>
                    )}
                    {connection?.connection_status === null && (
                        <button disabled={disabledButton === connection.attendee_id} className="connect-button" onClick={() => {
                            createUserConnection(connection)
                            setConnectionButton(actionButtons.CONNECT);
                            setSelectedConnections(connection);

                        }} >{actionButtons.CONNECT}</button>
                    )}
                </>
            )}

            {/* "My Network" Tab */}
            {activeTab === 'My Network' && connection?.connection_status === networkEntity.ACCEPT && (
                <>
                    <button className='options-button'>
                        <Icon
                            icon="material-symbols-light:chat-outline-rounded"
                            height={24}
                            width={24}
                            onClick={() => goToChatRoom(connection?.attendee_id)}
                        />
                    </button>
                    <button className="options-button" onClick={handleToggleRemoveConnection}>
                        ⋮
                    </button>
                </>
            )}

            {/* "Received" Tab */}
            {activeTab === 'Received' && connection?.connection_status === networkEntity.SENT && connection?.created_by !== userId && (
                <div className="action-buttons">
                    <Icon
                        role="button"
                        icon={`material-symbols-light:cancel-outline`}
                        width={"44px"}
                        height={"44px"}
                        className="primary-color-icons"
                        onClick={() => {
                            actionUpdate(connection, networkEntity.REJECT),
                                setConnectionButton(actionButtons.REJECT),
                                setRemoveConnectionId(false)
                        }}
                    />
                    <Icon
                        role="button"
                        icon={`material-symbols-light:check-circle-outline`}
                        width={"44px"}
                        height={"44px"}
                        className="primary-color-icons"
                        onClick={() => {
                            actionUpdate(connection, networkEntity.ACCEPT),
                                setConnectionButton(actionButtons.ACCEPT),
                                setRemoveConnectionId(false)
                        }}
                    />
                </div>
            )}

            {/* Remove Connection Pop-up */}
            {showRemoveConnection && (
                <div
                    role="button"
                    className="remove-conncetion-contaner"
                    aria-hidden="true"
                    ref={dropdownRef}
                    onClick={() => {
                        handleConnectionClick(connection),
                            handleToggleRemoveConnection(),
                            setRemoveConnectionId(true)
                    }}
                >
                    <div className="remove-conncetion-sec">
                        <span>Remove Connection</span>
                    </div>
                </div>
            )}
        </div >
    );
};

export default ConnectionActions;
