import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/AuthContext";
import { centralLoginUrl } from "../config/app";
import { logout } from "../reusable/common";
import { useNavigate } from "react-router-dom";
import { jwtToken } from "../crud/axios";
import { SwipeableDrawer } from "@mui/material";
import { isKHDomain } from "../config/envs";
import { getSubdomain } from "../utils/core";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const { user, isRegistered, loading } = useUser();
	const [drawerOpen, setDrawerOpen] = useState(false);

	const redirectToRoute = location.pathname !== "/" ? location.pathname : "";

	const logoutAndLogin = () => {
		logout();
		login();
	};
	const handleDrawerClose = () => {
		// Prevent closing the drawer on outside click or swipe
	};

	let startTouch = 0;
	const SWIPE_THRESHOLD = 30;

	const handleTouchStart = (event) => {
		startTouch = event.touches[0].clientY; // Save the initial touch position
	};

	const handleTouchMove = (event) => {
		const endTouch = event.touches[0].clientY;

		if (endTouch - startTouch > SWIPE_THRESHOLD) {
			event.preventDefault();
			setDrawerOpen(false);
			navigate("/");
		}
	};

	const login = () => {
		if (!isKHDomain) window.location.href = `/login-account?redirect=${redirectToRoute}`;
		if (isKHDomain)
			window.location.href = `${centralLoginUrl}/login?redirect=AttendeeApp${redirectToRoute ? `&redirectToRoute=${redirectToRoute}` : ""}${getSubdomain() ? `&sub_domain=${getSubdomain()}` : ""}`;
	};

	useEffect(() => {
		if (!user && !loading && !jwtToken) {
			setDrawerOpen(true);
		}
		if (user && !isRegistered && !loading && jwtToken) {
			setDrawerOpen(true);
		}
	}, [user, loading, jwtToken, isRegistered]);

	if (!user && !loading && !jwtToken) {
		return (
			<>
				<div className="modal-overlay d-none d-sm-flex">
					<div className="announcement-overlay">
						<div className="announcement-card">
							<div className="announcement-body text-center">
								<h3 className="announce_new text-center">
									Authentication Required
								</h3>
								<p className="warning-txt">
									This module can be accessed only by event registered users.
									Login with registered email address to proceed.
								</p>
							</div>

							<div className="modal-cta-wrapper">
								<button className="explore-button" onClick={login}>
									Log In
								</button>
							</div>
						</div>
					</div>
				</div>
				<SwipeableDrawer
					className="d-flex d-sm-none"
					anchor="bottom"
					open={drawerOpen}
					onClose={handleDrawerClose}
					onTouchStart={handleTouchStart}  // Handle touch start to track swipe
					onTouchMove={handleTouchMove}
					onOpen={() => setDrawerOpen(true)}
					disableSwipeToOpen
					swipeAreaWidth={5}
					BackdropProps={{
						onClick: (e) => e.stopPropagation(),  // This prevents the drawer from closing
					}}
				>
					<div className="">
						<div className="drawer-top-line" aria-hidden="true"></div>
						<div className="announcement-overlay">
							<div className="announcement-card-swippable">
								<div className="announcement-body text-center">
									<h3 className="announce_new text-center">
										Authentication Required
									</h3>
									<p className="warning-txt">
										This module can be accessed only by event registered users.
										Login with registered email address to proceed.
									</p>
								</div>

								<div className="modal-cta-wrapper">
									<button className="explore-button" onClick={login}>
										Log In
									</button>
								</div>
							</div>
						</div>
					</div>
				</SwipeableDrawer>
			</>
		);
	}
	if (!isRegistered && !loading && user && jwtToken)
		return (
			<>
				<div className="modal-overlay d-none d-sm-flex">
					<div className="announcement-overlay">
						<div className="announcement-card">
							<div className="announcement-body text-center">
								<h3 className="announce_new text-center">
									Authentication Required
								</h3>
								<p className="warning-txt">
									Entered email address is not registered with the event. Please
									enter another email address.
								</p>
							</div>

							<div className="modal-cta-wrapper">
								<button className="explore-button" onClick={logoutAndLogin}>
									Log In
								</button>
							</div>
						</div>
					</div>
				</div>
				<SwipeableDrawer
					className="d-flex d-sm-none"
					anchor="bottom"
					open={drawerOpen}
					onClose={handleDrawerClose}
					onTouchStart={handleTouchStart}  // Handle touch start to track swipe
					onTouchMove={handleTouchMove}
					onOpen={() => setDrawerOpen(true)}
					disableSwipeToOpen
					swipeAreaWidth={5}
					BackdropProps={{
						onClick: (e) => e.stopPropagation(),  // This prevents the drawer from closing
					}}

				>
					<div className="drawer-top-line" aria-hidden="true"></div>

					<div className="announcement-overlay">
						<div className="announcement-card">
							<div className="announcement-body text-center">
								<h3 className="announce_new text-center">
									Authentication Required
								</h3>
								<p className="warning-txt unregisteredemail-text">
									Entered email address is not registered with the event. Please
									enter another email address.
								</p>
							</div>

							<div className="modal-cta-wrapper">
								<button className="explore-button" onClick={logoutAndLogin}>
									Log In
								</button>
							</div>
						</div>
					</div>
				</SwipeableDrawer>
			</>
		);
	return <>{children}</>;
};

export default ProtectedRoute;
